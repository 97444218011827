import React, { useMemo, useState } from "react";
import {
  Card,
  CardContent,
  Stack,
  Typography,
  IconButton,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { API_ENDPOINT } from "../../utils/constants";
import useCommonGetInit from "../../hooks/useCommonGetInit";
import { exportToExcel } from "../../utils/util";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CloseIcon from "@mui/icons-material/Close";

const DesignationWiseCard2 = () => {
  const { data: designationwiseData, isLoading: isDesignationwiseLoading } =
    useCommonGetInit(`${API_ENDPOINT}user/a/get-designationwise-registrations`);
  const [isFullscreen, setFullscreen] = useState(false);

  // Transform the data to match the format for Recharts
  const dataByDesignation = useMemo(() => {
    if (
      !designationwiseData ||
      !Array.isArray(designationwiseData.registrations)
    )
      return [];

    return designationwiseData.registrations
      .filter((item) => item.designation) // Keep only items with a valid designation
      .map((item) => ({
        designation: item.designation,
        count: item.count,
      }));
  }, [designationwiseData]);

  const handleFullscreenToggle = () => setFullscreen((prev) => !prev);

  // Limit items for compact view
  const displayedData = isFullscreen
    ? dataByDesignation
    : dataByDesignation.slice(0, 6); // Show top 6 designations in compact view

  if (isDesignationwiseLoading) {
    return (
      <Card>
        <CardContent>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            height={250}
          >
            <CircularProgress />
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      <Card>
        <CardContent>
          <Stack direction={"row"} spacing={2} alignItems="center">
            <Typography flex={1} variant="h6">
              Registrations by Designations
            </Typography>
            <Button
              size="small"
              variant="outlined"
              startIcon={<FileDownloadIcon />}
              onClick={() =>
                exportToExcel(
                  dataByDesignation.map(({ designation, count }) => ({
                    designation,
                    count,
                  })),
                  "Registrations_by_Designation"
                )
              }
            >
              Export
            </Button>
            <IconButton onClick={handleFullscreenToggle}>
              <FullscreenIcon />
            </IconButton>
          </Stack>
          <ResponsiveContainer width="100%" height={250}>
            <BarChart data={displayedData} layout="vertical">
              <XAxis type="number" />
              <YAxis type="category" dataKey="designation" width={150} />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#82ca9d">
                <LabelList dataKey="count" position="right" />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </CardContent>
      </Card>

      {/* Fullscreen Chart Dialog */}
      <Dialog
        open={isFullscreen}
        onClose={handleFullscreenToggle}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent
          sx={{
            maxHeight: "80vh", // Adjust this as needed, sets a vertical limit
            overflowY: "auto", // Enables scrolling if content exceeds maxHeight
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h6">
              Full-Screen Registrations by Designations
            </Typography>
            <IconButton onClick={handleFullscreenToggle}>
              <CloseIcon />
            </IconButton>
          </Stack>

          {/* Dynamically set height based on number of data points */}
          <ResponsiveContainer
            width="100%"
            height={dataByDesignation.length * 50} // 50px per item as an example
          >
            <BarChart data={dataByDesignation} layout="vertical">
              <XAxis type="number" />
              <YAxis type="category" dataKey="designation" width={150} />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#82ca9d">
                <LabelList dataKey="count" position="right" />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DesignationWiseCard2;
