import React, { useMemo, useState } from "react";
import { API_ENDPOINT } from "../../utils/constants";
import useCommonGetInit from "../../hooks/useCommonGetInit";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Stack,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
  PieChart,
  Pie,
  Cell,
  LineChart,
  Line,
  Legend,
} from "recharts";

// All possible roles
const ALL_ROLES = [
  "EXHIBITOR",
  "DELEGATION",
  "VIP",
  "EC",
  "SERVICE",
  "MEDIA",
  "STUDENT",
  "VISITOR",
  "visitor",
];

// Role labels
const ROLE_LABELS = {
  EXHIBITOR: "Exhibitor",
  DELEGATION: "Delegation",
  VIP: "VIP",
  EC: "EC",
  SERVICE: "Service",
  MEDIA: "Media",
  STUDENT: "Student",
  VISITOR: "Onsite Visitor", // uppercase VISITOR means Onsite Visitor
  visitor: "Visitor",
};

const DAYS = ["day1", "day2", "day3", "day4", "day5", "day6", "day7"];
const startDate = new Date("2024-12-06");

const hourlyTemplate = {
  "9am-10am": 0,
  "10am-11am": 0,
  "11am-12pm": 0,
  "12pm-1pm": 0,
  "1pm-2pm": 0,
  "2pm-3pm": 0,
  "3pm-4pm": 0,
  "4pm-5pm": 0,
};

function getDateForDay(dayKey) {
  const dayIndex = DAYS.indexOf(dayKey);
  if (dayIndex === -1) return null;
  const selectedDate = new Date(startDate);
  selectedDate.setDate(startDate.getDate() + dayIndex);
  return selectedDate.toISOString().slice(0, 10); // YYYY-MM-DD
}

function getHourlyBucket(dateObj) {
  const hour = dateObj.getHours();
  if (hour >= 9 && hour < 10) return "9am-10am";
  if (hour >= 10 && hour < 11) return "10am-11am";
  if (hour >= 11 && hour < 12) return "11am-12pm";
  if (hour >= 12 && hour < 13) return "12pm-1pm";
  if (hour >= 13 && hour < 14) return "1pm-2pm";
  if (hour >= 14 && hour < 15) return "2pm-3pm";
  if (hour >= 15 && hour < 16) return "3pm-4pm";
  if (hour >= 16 && hour < 17) return "4pm-5pm";
  return null;
}

// Colors for Pie Chart segments
const PIE_COLORS = [
  "#8884d8",
  "#82ca9d",
  "#ffc658",
  "#ff7300",
  "#413ea0",
  "#a4de6c",
  "#d0ed57",
  "#00C49F",
  "#FFBB28",
];

// Assign colors to days (for line chart)
function getColorForIndex(index) {
  const colors = [
    "#8884d8", // day1
    "#82ca9d", // day2
    "#ffc658", // day3
    "#ff7300", // day4
    "#413ea0", // day5
    "#a4de6c", // day6
    "#d0ed57", // day7
  ];
  return colors[index % colors.length];
}

// Legend payload for the line chart (static, so lines never disappear)
const legendPayload = DAYS.map((dayKey, index) => ({
  value: dayKey,
  type: "line",
  color: getColorForIndex(index),
}));

const DayWiseFootfallReport = () => {
  const [refresh, setRefresh] = useState(false);
  const [selectedDay, setSelectedDay] = useState("day1");

  // Initially, all days visible
  const [visibleDays, setVisibleDays] = useState(new Set(DAYS));

  const { data, isLoading } = useCommonGetInit(
    `${API_ENDPOINT}user/scanned-users-footfall-small?pb=imtex2025&refresh=${refresh}`
  );

  // Aggregate role counts
  const roleCounts = useMemo(() => {
    const counts = {};
    if (Array.isArray(data)) {
      data.forEach((record) => {
        const role = record.role;
        if (!counts[role]) counts[role] = 0;
        counts[role]++;
      });
    }
    return counts;
  }, [data]);

  // Ensure each role is shown even if zero
  const rolesWithCounts = ALL_ROLES.map((role) => ({
    role,
    count: roleCounts[role] || 0,
  }));

  // Pie data
  const pieData = useMemo(() => {
    return rolesWithCounts.map(({ role, count }) => ({
      name: ROLE_LABELS[role] || role,
      value: count,
    }));
  }, [rolesWithCounts]);

  // Hourly data for the selected day (Bar Chart)
  const hourlyData = useMemo(() => {
    if (!data || !Array.isArray(data)) return [];
    const hourlyCount = { ...hourlyTemplate };
    const currentDayDateString = getDateForDay(selectedDay);
    if (!currentDayDateString) return [];

    for (const record of data) {
      if (record[selectedDay] === 1) {
        const timestampKey = `${selectedDay}timestamp`;
        const timestamp = record[timestampKey];
        if (timestamp) {
          const dateObj = new Date(timestamp);
          const dateOnly = dateObj.toISOString().slice(0, 10);
          if (dateOnly === currentDayDateString) {
            const bucket = getHourlyBucket(dateObj);
            if (bucket && hourlyCount[bucket] !== undefined) {
              hourlyCount[bucket] += 1;
            }
          }
        }
      }
    }

    return Object.keys(hourlyCount).map((key) => ({
      name: key,
      count: hourlyCount[key],
    }));
  }, [data, selectedDay]);

  // Daywise comparison (Line Chart) data
  const lineChartData = useMemo(() => {
    if (!data || !Array.isArray(data)) return [];

    const hours = Object.keys(hourlyTemplate);
    const combinedData = hours.map((hour) => ({ name: hour }));

    for (const dayKey of DAYS) {
      const dayDateString = getDateForDay(dayKey);
      const dailyCounts = { ...hourlyTemplate };

      for (const record of data) {
        if (record[dayKey] === 1) {
          const timestampKey = `${dayKey}timestamp`;
          const timestamp = record[timestampKey];
          if (timestamp) {
            const dateObj = new Date(timestamp);
            const dateOnly = dateObj.toISOString().slice(0, 10);
            if (dateOnly === dayDateString) {
              const bucket = getHourlyBucket(dateObj);
              if (bucket && dailyCounts[bucket] !== undefined) {
                dailyCounts[bucket] += 1;
              }
            }
          }
        }
      }

      // Integrate this day's counts into combinedData
      for (let i = 0; i < combinedData.length; i++) {
        const hourKey = combinedData[i].name;
        combinedData[i][dayKey] = dailyCounts[hourKey];
      }
    }

    return combinedData;
  }, [data]);

  // Custom legend for line chart: use static legendPayload
  const renderCustomLegend = () => {
    return (
      <Box display="flex" flexWrap="wrap" gap={2} ml={2}>
        {legendPayload.map((entry) => {
          const isActive = visibleDays.has(entry.value);
          return (
            <Box
              key={entry.value}
              onClick={() => {
                // Toggle day visibility
                setVisibleDays((prev) => {
                  const newSet = new Set(prev);
                  if (newSet.has(entry.value)) {
                    newSet.delete(entry.value);
                  } else {
                    newSet.add(entry.value);
                  }
                  return newSet;
                });
              }}
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  backgroundColor: entry.color,
                  borderRadius: "50%",
                  marginRight: 1,
                }}
              />
              <Typography
                variant="body2"
                sx={{
                  textDecoration: isActive ? "none" : "line-through",
                }}
              >
                {entry.value}
              </Typography>
            </Box>
          );
        })}
      </Box>
    );
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>
        Overall Footfall by Designation
      </Typography>
      {isLoading ? (
        <Typography>Loading data...</Typography>
      ) : (
        <>
          {/* Role Counts Cards */}
          <Grid container spacing={2} sx={{ mb: 4 }}>
            {rolesWithCounts.map(({ role, count }) => (
              <Grid item xs={12} sm={6} md={3} lg={2} key={role}>
                <Card
                  variant="outlined"
                  sx={{ borderRadius: 2, textAlign: "center" }}
                >
                  <CardContent>
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      {ROLE_LABELS[role] || role}
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 700, mt: 1 }}>
                      {count}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          {/* Controls for Day-wise Hourly Footfall */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
              mb: 2,
            }}
          >
            <Typography variant="h5" component="div">
              Day-wise Hourly Footfall
            </Typography>
            <Stack direction="row" spacing={2} alignItems="center">
              <FormControl size="small" sx={{ minWidth: 120 }}>
                <InputLabel id="day-select-label">Select Day</InputLabel>
                <Select
                  labelId="day-select-label"
                  id="day-select"
                  value={selectedDay}
                  label="Select Day"
                  onChange={(e) => setSelectedDay(e.target.value)}
                >
                  {DAYS.map((dayKey) => (
                    <MenuItem key={dayKey} value={dayKey}>
                      {dayKey}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <IconButton
                onClick={() => setRefresh((prev) => !prev)}
                color="primary"
              >
                <RefreshIcon />
              </IconButton>
            </Stack>
          </Box>

          {/* Bar Chart for selected day */}
          <Box sx={{ width: "100%", height: 400, mb: 4 }}>
            <ResponsiveContainer>
              <BarChart
                data={hourlyData}
                margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis allowDecimals={false} />
                <Tooltip />
                <Bar dataKey="count" fill="#8884d8">
                  <LabelList dataKey="count" position="top" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Box>

          {/* Daywise Comparison (Line Chart) */}
          <Typography variant="h5" component="div" sx={{ mb: 2 }}>
            Daywise Comparison
          </Typography>
          <Box sx={{ width: "100%", height: 400 }}>
            <ResponsiveContainer>
              <LineChart
                data={lineChartData}
                margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis allowDecimals={false} />
                <Tooltip />
                <Legend content={renderCustomLegend} />
                {DAYS.map((dayKey, index) => {
                  const isActive = visibleDays.has(dayKey);
                  return (
                    <Line
                      key={dayKey}
                      type="monotone"
                      dataKey={dayKey}
                      stroke={getColorForIndex(index)}
                      dot={false}
                      activeDot={{ r: 8 }}
                      strokeOpacity={isActive ? 1 : 0}
                    />
                  );
                })}
              </LineChart>
            </ResponsiveContainer>
          </Box>

          <Typography variant="h6" sx={{ mb: 2, fontWeight: 500 }}>
            Designation Distribution
          </Typography>
          <Box sx={{ width: "100%", height: 300, mb: 4 }}>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={pieData}
                  dataKey="value"
                  nameKey="name"
                  outerRadius={100}
                  fill="#8884d8"
                  label
                >
                  {pieData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={PIE_COLORS[index % PIE_COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Box>
        </>
      )}
    </Box>
  );
};

export default DayWiseFootfallReport;
