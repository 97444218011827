export const LOCAL = "local";
export const ADMIN = "admin";
export const USER = "user";
export const DRAWER_WIDTH = 240;
export const PRODUCTION = "production";
export const TRANSPARENCY = 0.8;
export const state = PRODUCTION;
export const API_ENDPOINT =
  state === PRODUCTION
    ? "https://apis.expoplanner.in/"
    : "http://localhost:5002/";

export const TimeZoneIndia = {
  timeZone: "Asia/Kolkata", // IST Timezone
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
};
export const TimeOnlyZone = {
  timeZone: "Asia/Kolkata",
  hour: "2-digit",
  minute: "2-digit",
};
