import {
  Alert,
  AlertTitle,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import PrintIcon from "@mui/icons-material/Print";
import axios from "axios";
import { API_ENDPOINT } from "../../utils/constants";
import { printAll, printRow } from "./ImtexBadgeDimension";
import { toastError } from "../../utils/util";
import { getOnsiteHeader, isOnsiteActivated } from "./utils";
import { useNavigate, useParams } from "react-router-dom";
import useCommonGetInit from "../../hooks/useCommonGetInit";
import MuPb from "../../widgets/MuPb";

const BulkStudent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    data: Mdata,
    isLoading,
    isError,
    error: error,
  } = useCommonGetInit(`${API_ENDPOINT}user/check-onsite?id=${id}`);
  const [institutionName, setInstitutionName] = useState("");
  const [numBadges, setNumBadges] = useState("");
  const [students, setStudents] = useState();

  const handleProceed = async () => {
    // Validate institution name
    if (!institutionName.trim()) {
      toast.error("Institution name is required.");
      return; // Exit the function if validation fails
    }
    // Validate number of badges
    if (!numBadges) {
      toast.error("Number of badges is required.");
      return; // Exit the function if validation fails
    } else if (isNaN(numBadges) || numBadges < 1) {
      toast.error("Number of badges must be a positive integer.");
      return; // Exit the function if validation fails
    }
    const dataToSave = {
      num: numBadges,
      companyName: institutionName,
    };
    try {
      const { data } = await axios.post(
        `${API_ENDPOINT}user/save-bulk-student-num`,
        dataToSave,
        getOnsiteHeader(Mdata.module)
      );
      setStudents(data);
      printAll(data);
    } catch (error) {
      toastError(error);
    }
  };
  useEffect(() => {
    if (Mdata) {
      if (!isOnsiteActivated(Mdata.module)) {
        navigate(-1);
      }
    }
  }, [Mdata]);

  if (isLoading) {
    return <MuPb />;
  }
  if (isError) {
    return <Alert severity="error">{error?.message}</Alert>;
  }

  return (
    <Box>
      <Typography mt={2} variant="h5" textAlign={"center"}>
        Bulk Student Badges
      </Typography>
      <Alert
        severity="error"
        icon={<InfoIcon fontSize="large" />}
        sx={{ alignItems: "flex-start" }}
      >
        <AlertTitle>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", color: "#d32f2f" }}
          >
            Important Notice
          </Typography>
        </AlertTitle>
        <Typography variant="body1">
          Please ensure you are connected to the printer while accessing this
          feature. Once badges are generated:
        </Typography>
        <ul style={{ margin: 0, paddingLeft: "1.5rem" }}>
          <li>
            <Typography variant="body1">
              IDs are serialized, and each badge will have a unique barcode ID.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              To prevent wastage of IDs and badges, always verify the printer
              connection.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Reuse this feature to continue printing from the next serial
              number.
            </Typography>
          </li>
        </ul>
        <Typography variant="body1" sx={{ marginTop: 1, fontWeight: "bold" }}>
          Failure to follow this process might result in wasted IDs and
          unprinted badges.
        </Typography>
      </Alert>
      <Box
        mt={4}
        sx={{
          maxWidth: 600,
          ml: "auto",
          mr: "auto",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        <TextField
          fullWidth
          size="small"
          sx={{ mt: 2 }}
          label="Institution Name"
          variant="outlined"
          value={institutionName}
          onChange={(e) => setInstitutionName(e.target.value)}
        />
        <TextField
          fullWidth
          size="small"
          sx={{ mt: 2 }}
          label="Number of Badges"
          variant="outlined"
          type="number"
          value={numBadges}
          onChange={(e) => setNumBadges(e.target.value)}
        />
        <Button
          size="small"
          fullWidth
          sx={{ mt: 2 }}
          variant="contained"
          color="primary"
          onClick={handleProceed}
        >
          Proceed
        </Button>
        <Alert severity="info">
          <Typography variant="body1">
            As you proceed, IDs will be generated, and the bulk printer will
            launch. The list will be shown below.
          </Typography>
        </Alert>
      </Box>
      {students && (
        <Box maxWidth={1200} width={"100%"} mt={3} mx="auto">
          <Box display={"flex"}>
            <Typography variant="h6" flex={1}>
              No. of Students: {students.length}
            </Typography>
            <Button onClick={() => printAll(students)} variant="contained">
              Print
            </Button>
          </Box>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Barcode ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Institution</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Print</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students.map((student, index) => (
                  <TableRow key={index}>
                    <TableCell>{student.barcode_id}</TableCell>
                    <TableCell>
                      {student.firstName} {student.lastName}
                    </TableCell>
                    <TableCell>{student.companyName}</TableCell>
                    <TableCell>{student.email}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          printRow(student);
                        }}
                        size="small"
                        variant="contained"
                        startIcon={<PrintIcon />}
                      >
                        Print
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default BulkStudent;
