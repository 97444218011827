import {
  Alert,
  Box,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useCallback, useEffect, useState } from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { LoadingButton } from "@mui/lab";
import _ from "lodash";
import { printRow } from "./ImtexBadgeDimension";
import { API_ENDPOINT } from "../../utils/constants";
import useCommonGetInit from "../../hooks/useCommonGetInit";
import CustomCircularP from "../../widgets/CustomCircularP";
import { SPOT_REG_CATEGORIES } from "./SpotRegistration";
import { useNavigate, useParams } from "react-router-dom";
import { getOnsiteHeader, isOnsiteActivated } from "./utils";
import MuPb from "../../widgets/MuPb";
import axios from "axios";
const SearchPrint = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    data: Mdata,
    isLoading: mloading,
    isError: ismerror,
    error: merror,
  } = useCommonGetInit(`${API_ENDPOINT}user/check-onsite?id=${id}`);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [searchQ, setSearchQ] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [page, setPage] = useState(1);
  const [debouncedSearchQ, setDebouncedSearchQ] = useState(searchQ);
  useEffect(() => {
    if (Mdata) {
      if (!isOnsiteActivated(Mdata.module)) {
        navigate(-1);
      }
    }
  }, [Mdata]);

  const handleSearch = (e) => {
    const vl = e.target.value;
    setSearchQ(vl);
  };
  const debounceSearch = useCallback(
    _.debounce((query) => {
      setDebouncedSearchQ(query);
    }, 300), // 300ms delay
    []
  );

  useEffect(() => {
    debounceSearch(searchQ);
  }, [searchQ, debounceSearch]);

  const fetchVisitors = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${API_ENDPOINT}user/get-onsite-users?page=${page}&sq=${debouncedSearchQ}&usertype=${selectedFilter}`,
        getOnsiteHeader(Mdata.module)
      );

      setData(data);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (Mdata) {
      fetchVisitors();
    }
  }, [Mdata, page, debouncedSearchQ, selectedFilter]);

  if (mloading) {
    return <MuPb />;
  }
  if (ismerror) {
    return <Alert severity="error">{merror?.message}</Alert>;
  }

  const onKeyPress = (button) => {
    console.log("Button pressed", button);
    // Handle specific button presses if needed
  };
  return (
    <Box>
      <Box mt={2} display={"flex"}>
        <Box flex={1} ml={2} mr={2}>
          <Paper
            sx={{
              padding: "2px 4px 2px 10px",
              backgroundColor: "#f4f4f4",
              display: "flex",
              alignItems: "center",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder={"Search Users by Name/Company Name/Email/mobile"}
              value={searchQ}
              onChange={handleSearch}
              inputProps={{
                "aria-label": "Search Users by Name/Company Name/Email/mobile",
              }}
            />
            <IconButton type="submit" sx={{ p: "8px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
          <Keyboard
            onChange={(input) => {
              setSearchQ(input);
            }}
            onKeyPress={onKeyPress}
          />
        </Box>
        <FormControl
          sx={{ maxWidth: "200px", alignSelf: "center" }}
          fullWidth
          size="small"
        >
          <InputLabel id="demo-simple-select-label">Filter by</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="selectedCountry"
            value={selectedFilter}
            label="Filter by"
            onChange={(e) => setSelectedFilter(e.target.value)}
          >
            <MenuItem value={""}>ALL</MenuItem>
            {SPOT_REG_CATEGORIES.map((target, i) => (
              <MenuItem key={i} value={target}>
                {target}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box>
        <CustomCircularP show={loading} />
        <TableContainer sx={{ mt: 2 }} component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Designation</TableCell>
                <TableCell align="right">Company</TableCell>
                <TableCell align="right">Email</TableCell>
                <TableCell align="right">Phone</TableCell>
                <TableCell align="right">Print</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.data?.map((row) => (
                <TableRow
                  key={row.user_id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.title} {row.firstName} {row.lastName}
                    {row.name}
                  </TableCell>
                  <TableCell align="right">
                    {row.designation}
                    {row.education}
                  </TableCell>
                  <TableCell align="right">{row.companyName}</TableCell>
                  <TableCell align="right">{row.email}</TableCell>
                  <TableCell align="right">{row.mobileNo}</TableCell>
                  <TableCell align="right">
                    <IconButton size="small" onClick={() => printRow(row)}>
                      <LocalPrintshopIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default SearchPrint;
