import axios from "axios";
import React, { createContext, useState, useEffect } from "react";
import { ADMIN, API_ENDPOINT } from "../utils/constants";
import { extractDate, extractTime, getHeader, toastError } from "../utils/util";
import * as XLSX from "xlsx";

export const ExportContext = createContext();

export const ExportProvider = ({ children }) => {
  const [visitorExporting, setVisitorExporting] = useState(false);
  const [exhibitorExporting, setExhibitorExporting] = useState(false);
  const [tmExporting, setTmExporting] = useState(false);
  const [oesExporting, setOesExporting] = useState(false);
  const [productExporting, setProductExporting] = useState(false);

  const exportVisitors = async () => {
    try {
      setVisitorExporting(true);
      const { data } = await axios.get(
        `${API_ENDPOINT}user/a/get-all-visitors`,
        getHeader(ADMIN)
      );

      const filteredData = data?.map((item) => {
        return {
          "User ID": item.user_id,
          Title: item.title,
          "Barcode ID": item.barcode_id,
          Designation: item.designation,
          "Events List": Array.isArray(item.eventCategory)
            ? item.eventCategory?.join(", ")
            : [],
          "First Name": item.firstName,
          "Last Name": item.lastName,
          "Phone Number": item.mobileNo,
          Email: item.email,
          "Company Name": item.companyName,
          Address: item.address,
          Country: item.country?.name,
          State: item.stateRegion?.name,
          City: item.city?.name,
          "Postal Code": item.pinZip,
          Website: item.website,
          Telephone: item.telephoneNo,
          Department: item.department,
          "Event Discovery": item.eventDiscovery,
          "Industry Sector": item.industrySector,
          "Product Categories": Array.isArray(item.productCategories)
            ? item.productCategories?.join(", ")
            : [],
          "QR Link": item.qrLink,
          "Profile Image": item.profile_image,
          Date: extractDate(item.createdOn),
          Time: extractTime(item.createdOn),
        };
      });

      const ws = XLSX.utils.json_to_sheet(filteredData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Visitors");
      XLSX.writeFile(wb, "visitors.xlsx");
    } catch (error) {
      toastError(error);
    } finally {
      setVisitorExporting(false);
    }
  };
  const exportExhibitors = async () => {
    try {
      setExhibitorExporting(true);
      const { data } = await axios.get(
        `${API_ENDPOINT}user/a/get-all-exhibitors`,
        getHeader(ADMIN)
      );

      const filteredData = data.map((item) => {
        const myCats = (item.productCategories.value || []).reduce(
          (acc, cat) => {
            const { mainCat, subSubCat } = cat;
            if (!acc[mainCat]) {
              acc[mainCat] = [];
            }
            acc[mainCat].push(subSubCat);
            return acc;
          },
          {}
        );
        const formattedString = Object.entries(myCats)
          .map(
            ([mainCat, subSubCats]) => `${mainCat}: ${subSubCats.join(", ")}`
          )
          .join(", ");

        const aboutString = (item.about?.value || "").replace(
          /<\/?[^>]+(>|$)/g,
          ""
        );

        return {
          "Exhibitor ID": item.user_id,
          profile_image: item.profile_image,
          Username: item.username,
          Password: item.password,
          [item.companyName.label]: item.companyName.value,
          [item.about?.label || "About"]: aboutString,
          [item.contactSalutation.label]: item.contactSalutation.value,
          [item.contactFirstName.label]: item.contactFirstName.value,
          [item.contactLastName.label]: item.contactLastName.value,
          [item.contactDesignation.label]: item.contactDesignation.value,
          [item.mobile.label]: item.mobile.value,
          [item.companyEmail.label]: item.companyEmail.value,
          [item.contactEmail.label]: item.contactEmail.value,
          [item.address1.label]: item.address1.value,
          [item.address2.label]: item.address2.value,
          [item.address3.label]: item.address3.value,
          [item.country.label]: item.country?.value?.name,
          [item.state.label]: item.state?.value?.name,
          [item.city.label]: item.city?.value?.name,
          [item.pin.label]: item.pin.value,
          [item.phone.label]: item.phone.value,
          [item.fax.label]: item.fax.value,
          [item.website.label]: item.website.value,
          [item.companyShortName.label]: item.companyShortName.value,
          [item.fascia.label]: item.fascia.value,
          [item.weAre.label]: item.weAre.value,
          [item.industrySector.label]: (item.industrySector.value || [])?.join(
            ", "
          ),
          [item.exhibitorCategory.label]: item.exhibitorCategory.value,
          [item.productCategories.label]: formattedString,
          [item.hallNumber.label]: item.hallNumber.value,
          [item.standNumber.label]: item.standNumber.value,
          [item.spaceType.label]: item.spaceType.value,
          [item.exhibitArrival.label]: item.exhibitArrival.value,
          [item.exhibitArrivalTimeFrom.label]:
            item.exhibitArrivalTimeFrom.value,
          [item.exhibitArrivalTimeTo.label]: item.exhibitArrivalTimeTo.value,
          [item.materialArrival.label]: item.materialArrival.value,
          [item.materialArrivalTimeFrom.label]:
            item.materialArrivalTimeFrom.value,
          [item.materialArrivalTimeTo.label]: item.materialArrivalTimeTo.value,
          [item.dismantling.label]: item.dismantling.value,
          [item.dismantlingTimeFrom.label]: item.dismantlingTimeFrom.value,
          [item.dismantlingTimeTo.label]: item.dismantlingTimeTo.value,
          [item.standSize.label]: item.standSize.value,
        };
      });

      const ws = XLSX.utils.json_to_sheet(filteredData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Exhibitors");
      XLSX.writeFile(wb, "exhibitors.xlsx");
    } catch (error) {
      toastError(error);
    } finally {
      setExhibitorExporting(false);
    }
  };

  const exportProducts = async () => {
    try {
      setProductExporting(true);
      const { data } = await axios.get(
        `${API_ENDPOINT}admin/get-all-products`,
        getHeader(ADMIN)
      );
      const filteredData = data?.map((item) => {
        const myCats = (item.selectedCategories.value || []).reduce(
          (acc, cat) => {
            const { mainCat, subSubCat } = cat;
            if (!acc[mainCat]) {
              acc[mainCat] = [];
            }
            acc[mainCat].push(subSubCat);
            return acc;
          },
          {}
        );
        const formattedString = Object.entries(myCats)
          .map(
            ([mainCat, subSubCats]) => `${mainCat}: ${subSubCats.join(", ")}`
          )
          .join(", ");

        return {
          "Object ID": item._id,
          Owner: item.owner,
          image: (item.images || []).join(","),
          name: item.name,
          categories: formattedString,
          description: (item.description || "").slice(0, 200),
          brochure: item.brochure || "",
          assignTo: item.assignTo || "",
          productVideo: item.productVideo?.content || "",
          Date: extractDate(item.createdOn),
          Time: extractTime(item.createdOn),
        };
      });
      const ws = XLSX.utils.json_to_sheet(filteredData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Products");
      XLSX.writeFile(wb, "products.xlsx");
    } catch (error) {
      console.log(error);
      toastError(error);
    } finally {
      setProductExporting(false);
    }
  };
  const exportOes = async () => {
    try {
      setOesExporting(true);
      const { data } = await axios.get(
        `${API_ENDPOINT}admin/get-all-oes-submit`,
        getHeader(ADMIN)
      );

      const filteredData = data?.map((item) => {
        return {
          "Object ID": item._id,
          "Form Name": item.name,
          "Submitted By": item.submittedBy,
          "Submitted On": extractDate(item.createdOn),
          Supplier: (item.selectedSupplier || item.supplier)?.supplierName,
          "Form Data": item.form
            ? JSON.stringify(item.form)
            : item.user
            ? JSON.stringify(item.user)
            : "",
        };
      });
      const ws = XLSX.utils.json_to_sheet(filteredData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "OES");
      XLSX.writeFile(wb, "oes.xlsx");
    } catch (error) {
      toastError(error);
    } finally {
      setOesExporting(false);
    }
  };
  const exportTm = async () => {
    try {
      setTmExporting(true);
      const { data } = await axios.get(
        `${API_ENDPOINT}admin/get-all-teammembers`,
        getHeader(ADMIN)
      );
      const filteredData = data?.map((item) => {
        return {
          "Object ID": item._id,
          Owner: item.owner,
          profile_image: item.profile_image || "",
          firstName: item.firstName,
          lastName: item.lastName,
          phone: item.phone,
          email: item.email,
          role: item.role,
          designation: item.designation,
          Date: extractDate(item.createdOn),
          Time: extractTime(item.createdOn),
        };
      });
      const ws = XLSX.utils.json_to_sheet(filteredData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Team Members");
      XLSX.writeFile(wb, "team_members.xlsx");
    } catch (error) {
      toastError(error);
    } finally {
      setTmExporting(false);
    }
  };

  return (
    <ExportContext.Provider
      value={{
        exportVisitors,
        exportExhibitors,
        exhibitorExporting,
        visitorExporting,
        exportProducts,
        productExporting,
        exportOes,
        oesExporting,
        exportTm,
        tmExporting,
      }}
    >
      {children}
    </ExportContext.Provider>
  );
};
