import React, { useMemo, useState } from "react";
import {
  Card,
  CardContent,
  Stack,
  Typography,
  Button,
  IconButton,
  CircularProgress,
  Avatar,
  Dialog,
  DialogContent,
  Box,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { API_ENDPOINT } from "../../utils/constants";
import useCommonGetInit from "../../hooks/useCommonGetInit";
import { exportToExcel } from "../../utils/util";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CloseIcon from "@mui/icons-material/Close";

const CountrywiseRegistrationsCard2 = () => {
  const { data: countrywiseData, isLoading: isCountrywiseLoading } =
    useCommonGetInit(`${API_ENDPOINT}user/a/get-countrywise-registrations`);
  const [isFullscreen, setFullscreen] = useState(false);

  const dataByCountry = useMemo(() => {
    if (!countrywiseData || !Array.isArray(countrywiseData.registrations))
      return [];

    return countrywiseData.registrations
      .filter((item) => item.country) // Keep only valid countries
      .map((item) => ({
        country: item.country,
        count: item.count,
        flag: item.countryDetails.flag,
      }));
  }, [countrywiseData]);

  const handleFullscreenToggle = () => setFullscreen((prev) => !prev);

  const displayedData = isFullscreen
    ? dataByCountry
    : dataByCountry.slice(0, 5);

  const RenderYAxisTick = (props) => {
    const { x, y, payload } = props;
    const country = payload.value;
    const countryData = dataByCountry.find((item) => item.country === country);

    return (
      <g transform={`translate(${x},${y})`}>
        <foreignObject x={-150} y={-10} width={150} height={20}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar
              src={countryData?.flag}
              alt={country}
              sx={{ width: 20, height: 20 }}
            />
            <Typography variant="body2">{country}</Typography>
          </Stack>
        </foreignObject>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div
          style={{
            background: "#fff",
            border: "1px solid #ccc",
            padding: "10px",
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar
              src={data.flag}
              alt={data.country}
              sx={{ width: 20, height: 20 }}
            />
            <Typography variant="body2">
              {data.country}: {data.count} visitors
            </Typography>
          </Stack>
        </div>
      );
    }
    return null;
  };

  if (isCountrywiseLoading) {
    return (
      <Card>
        <CardContent>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            height={250}
          >
            <CircularProgress />
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      <Card>
        <CardContent>
          <Stack direction={"row"} spacing={2} alignItems="center">
            <Typography flex={1} variant="h6">
              Registrations by Country
            </Typography>
            <Button
              size="small"
              variant="outlined"
              startIcon={<FileDownloadIcon />}
              onClick={() =>
                exportToExcel(
                  dataByCountry.map(({ country, count }) => ({
                    country,
                    count,
                  })),
                  "Registrations_by_Country"
                )
              }
            >
              Export
            </Button>
            <IconButton onClick={handleFullscreenToggle}>
              <FullscreenIcon />
            </IconButton>
          </Stack>
          <ResponsiveContainer width="100%" height={250}>
            <BarChart
              data={displayedData}
              layout="vertical"
              margin={{ left: 20, right: 60 }}
            >
              <XAxis type="number" />
              <YAxis
                type="category"
                dataKey="country"
                width={150}
                tick={<RenderYAxisTick />}
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Bar dataKey="count" fill="#82ca9d">
                <LabelList dataKey="count" position="right" offset={20} />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </CardContent>
      </Card>
      <Dialog
        open={isFullscreen}
        onClose={handleFullscreenToggle}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent
          sx={{
            maxHeight: "80vh", // or another suitable viewport-based height
            overflowY: "auto", // enable vertical scrolling if content is taller
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h6">
              Full-Screen Registrations by Country
            </Typography>
            <IconButton onClick={handleFullscreenToggle}>
              <CloseIcon />
            </IconButton>
          </Stack>

          {/* Dynamically calculate chart height based on the number of bars */}
          <ResponsiveContainer
            width="100%"
            height={dataByCountry.length * 50} // For example, 50px per bar
          >
            <BarChart
              data={dataByCountry}
              layout="vertical"
              margin={{ top: 20, bottom: 20, left: 30, right: 50 }}
            >
              <XAxis type="number" />
              <YAxis
                type="category"
                dataKey="country"
                width={150}
                tick={<RenderYAxisTick />}
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Bar dataKey="count" fill="#82ca9d">
                <LabelList dataKey="count" position="right" offset={20} />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CountrywiseRegistrationsCard2;
