import React, { useContext } from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { LoadingButton } from "@mui/lab";
import { ExportContext } from "../../context/ExportContext";
const ExportVisitorsButton = () => {
  const { exportVisitors, visitorExporting } = useContext(ExportContext);
  return (
    <>
      <LoadingButton
        onClick={() => exportVisitors()}
        variant="outlined"
        loading={visitorExporting}
        startIcon={<FileDownloadIcon />}
      >
        Export to Excel
      </LoadingButton>
    </>
  );
};

export default ExportVisitorsButton;
