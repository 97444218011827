import React, { useState } from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  CardActions,
  Box,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  IconButton,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { Link, useNavigate, useParams } from "react-router-dom";
import useCommonGetInit from "../../hooks/useCommonGetInit";
import { API_ENDPOINT } from "../../utils/constants";
import MuPb from "../../widgets/MuPb";
import OtpInput from "./OtpInput";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { toast } from "react-toastify";
import { toastError } from "../../utils/util";
import {
  deleteOnsite,
  getOnsite,
  getOtpVerify,
  isOnsiteActivated,
  saveOnsite,
  saveOtpVerify,
} from "./utils";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import OnsiteReuseContainer from "./OnsiteReuseContainer";
const SharableOnsite = () => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [otp, setOtp] = useState("");
  const [currentGroup, setCurrentGroup] = useState();
  const [requesting, setRequesting] = useState(false);
  const { data, isLoading, isError, error } = useCommonGetInit(
    `${API_ENDPOINT}user/check-link?tk=${id}`
  );
  const {
    data: modules,
    isLoading: isMLoading,
    isError: isMerror,
    error: merror,
  } = useCommonGetInit(`${API_ENDPOINT}user/get-onsite-modules?pb=imtex2025`);
  if (isLoading || isMLoading) {
    return <MuPb />;
  }
  if (isError) {
    return (
      <Alert severity="error">
        {error?.message || "Oops! could not load data"}
      </Alert>
    );
  }
  const handleClose = () => {
    setOpen(false);
  };
  const onOtpEntered = async (otp) => {
    setOtp(otp);
  };
  const deactivateModule = (group) => {
    deleteOnsite(group._id);
    document.location.reload();
  };

  const requestOtp = async (cd) => {
    try {
      setRequesting(true);
      setCurrentGroup(cd);
      const { data } = await axios.get(
        `${API_ENDPOINT}user/request-activation-code?id=${cd._id}`,
        {
          headers: {
            "content-type": "application/json",
            dbauth: id,
          },
        }
      );
      toast.success(data.message);
      saveOtpVerify(data.token);
      onActivate(cd);
      setOpen(true);
    } catch (error) {
      toastError(error);
    } finally {
      setRequesting(false);
    }
  };

  const onActivate = (cd) => {
    setCurrentGroup(cd);
    setOpen(true);
  };
  const onSubmitOtp = async () => {
    try {
      setVerifying(true);
      const { data } = await axios.get(
        `${API_ENDPOINT}user/verify-activation-code?otp=${otp}&code=${getOtpVerify()}`,
        {
          headers: {
            "content-type": "application/json",
            dbauth: id,
          },
        }
      );
      toast.success(data.message);
      saveOnsite(currentGroup?._id, data.token);
      setOpen(false);
      setTimeout(() => {
        document.location.reload();
      }, 300);
    } catch (error) {
      toastError(error);
    } finally {
      setVerifying(false);
    }
  };
  const handleCopyLink = (link) => {
    const fullLink = `${window.location.origin}${link}`;
    navigator.clipboard.writeText(fullLink);
  };

  return (
    <OnsiteReuseContainer maxWidth="lg">
      <Grid container spacing={4}>
        {(modules || []).map((group, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              elevation={6}
              sx={{
                borderRadius: "12px",
                transition: "0.3s",
                height: "100%", // Ensures cards take full height of their Grid cell
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between", // Ensures even spacing between content and actions
                "&:hover": {
                  transform: "scale(1.05)",
                },
                borderLeft: `6px solid ${group.color}`,
                padding: "16px",
              }}
            >
              <CardContent>
                <Box display="flex" alignItems="center" mb={1}>
                  {/* {index === modules.length - 1 ? (
                    <AssessmentIcon sx={{ color: group.color, mr: 1 }} />
                  ) : (
                    <CheckCircleOutlineIcon
                      sx={{ color: group.color, mr: 1 }}
                    />
                  )} */}
                  <Typography variant="h5" fontWeight="bold" gutterBottom>
                    {group.title}
                  </Typography>
                </Box>
                <Typography variant="body1" color="textSecondary" mb={2}>
                  {group.description}
                </Typography>
                {isOnsiteActivated(group._id) && (
                  <Box
                    display="flex"
                    alignItems="center"
                    bgcolor="success.light"
                    borderRadius="8px"
                    p={1}
                    mt={2}
                  >
                    <CheckCircleIcon color="success" sx={{ mr: 1 }} />
                    <Typography
                      variant="body2"
                      color="white"
                      fontWeight="medium"
                    >
                      This module is activated
                    </Typography>
                  </Box>
                )}
              </CardContent>
              <CardActions
                sx={{
                  justifyContent: "flex-end",
                  paddingRight: "16px",
                  paddingBottom: "16px",
                }}
              >
                {!isOnsiteActivated(group._id) ? (
                  <LoadingButton
                    size="small"
                    loading={currentGroup?._id === group._id && requesting}
                    variant="contained"
                    color="primary"
                    endIcon={<InfoIcon />}
                    onClick={() => requestOtp(group)}
                  >
                    Activate
                  </LoadingButton>
                ) : (
                  <Stack direction={"row"} spacing={2}>
                    <>
                      <Link
                        to={
                          index < 7
                            ? `${group.link}${getOnsite(group._id)}`
                            : `${group.link}`
                        }
                      >
                        <Button size="small" variant="outlined">
                          Open
                        </Button>
                      </Link>

                      <IconButton
                        size="small"
                        onClick={() =>
                          handleCopyLink(
                            index < 7
                              ? `${group.link}${getOnsite(group._id)}`
                              : `${group.link}`
                          )
                        }
                      >
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    </>

                    <Button
                      size="small"
                      variant="outlined"
                      color="error"
                      startIcon={<HighlightOffIcon />}
                      onClick={() => deactivateModule(group)}
                      sx={{
                        borderColor: "error.main",
                        color: "error.main",
                        "&:hover": {
                          borderColor: "error.dark",
                          backgroundColor: "error.light",
                        },
                      }}
                    >
                      Deactivate
                    </Button>
                  </Stack>
                )}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Enter the OTP to activate {currentGroup?.title}
        </DialogTitle>
        <DialogContent>
          <OtpInput onComplete={onOtpEntered} />
          <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
            OTP is sent to Admin. Please contact Admin for the OTP.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <LoadingButton
            loading={verifying}
            onClick={onSubmitOtp}
            color="primary"
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </OnsiteReuseContainer>
  );
};

export default SharableOnsite;
