import React, { useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  TableFooter,
  Button,
  useMediaQuery,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import * as XLSX from "xlsx";
import { extractDate, extractTime } from "../../utils/util";
import useCommonGetInit from "../../hooks/useCommonGetInit";
import CustomCircularP from "../../widgets/CustomCircularP";
import { API_ENDPOINT } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
const cutoffDate = new Date("2024-12-06");
const DAYS = ["day1", "day2", "day3", "day4", "day5", "day6", "day7"];
const startDate = new Date("2024-12-06");

export default function DaywiseFootfall() {
  const [refresh, setRefresh] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const { data, isLoading } = useCommonGetInit(
    `${API_ENDPOINT}user/scanned-users-footfall?pb=imtex2025&refresh=${refresh}`
  );

  const rows = useMemo(() => {
    if (!data || !Array.isArray(data)) return [];

    const rolesMapping = {
      VIP: "vip",
      MEDIA: "media",
      STUDENT: "students",
      student: "students",
      BULK_STUDENT: "students",
      VISITOR: "visitor",
      visitor: "visitor",
      DELEGATION: "delegation",
      EC: "ec",
    };

    const createInitialCounts = () => {
      const template = {
        vip: 0,
        media: 0,
        students: 0,
        delegation: 0,
        ec: 0,
        preEventReg: 0,
        eventDaysReg: 0,
        totalVisitors: 0,
        repeatVisitors: 0,
        total: 0,
      };
      const counts = {};
      DAYS.forEach((day) => {
        counts[day] = { ...template };
      });
      return counts;
    };

    const initialCounts = createInitialCounts();

    const visitorCheck = {
      vip: {},
      media: {},
      students: {},
      delegation: {},
      ec: {},
      visitor: {},
      preEventReg: {},
      eventDaysReg: {},
    };

    data.forEach((user) => {
      const mappedRole = rolesMapping[user.role];
      if (!mappedRole) return;

      const registrationDate = new Date(user.createdOn);
      const isPreEventReg = registrationDate < cutoffDate;

      DAYS.forEach((day) => {
        if (user[day] && initialCounts[day]) {
          if (!visitorCheck[mappedRole][user.barcode_id]) {
            initialCounts[day][mappedRole] += 1;
            visitorCheck[mappedRole][user.barcode_id] = [day];
          } else {
            if (!visitorCheck[mappedRole][user.barcode_id].includes(day)) {
              visitorCheck[mappedRole][user.barcode_id].push(day);
              initialCounts[day].repeatVisitors += 1;
            }
          }

          if (mappedRole === "visitor") {
            const regRole = isPreEventReg ? "preEventReg" : "eventDaysReg";
            if (!visitorCheck[regRole][user.barcode_id]) {
              initialCounts[day][regRole] += 1;
              visitorCheck[regRole][user.barcode_id] = [day];
            } else {
              if (!visitorCheck[regRole][user.barcode_id].includes(day)) {
                visitorCheck[regRole][user.barcode_id].push(day);
              }
            }
          }
        }
      });
    });

    // Calculate totals for each day
    DAYS.forEach((day) => {
      const counts = initialCounts[day];
      counts.totalVisitors =
        counts.vip +
        counts.media +
        counts.students +
        counts.delegation +
        counts.ec +
        counts.preEventReg +
        counts.eventDaysReg;
      counts.total = counts.totalVisitors + counts.repeatVisitors;
    });

    // Convert days to actual dates
    const rowsData = DAYS.map((day, index) => {
      // Calculate the date for this day
      const currentDate = new Date(
        startDate.getTime() + index * 24 * 60 * 60 * 1000
      );
      // Format the date (you can format as needed)
      const dayDateString = currentDate.toLocaleDateString(undefined, {
        year: "numeric",
        month: "short",
        day: "numeric",
      });

      return {
        day: dayDateString,
        ...initialCounts[day],
      };
    });

    return rowsData;
  }, [data]);

  const columnSums = useMemo(() => {
    return rows.reduce(
      (totals, row) => ({
        vip: totals.vip + (row.vip || 0),
        media: totals.media + (row.media || 0),
        students: totals.students + (row.students || 0),
        delegation: totals.delegation + (row.delegation || 0),
        ec: totals.ec + (row.ec || 0),
        preEventReg: totals.preEventReg + (row.preEventReg || 0),
        eventDaysReg: totals.eventDaysReg + (row.eventDaysReg || 0),
        totalVisitors: totals.totalVisitors + (row.totalVisitors || 0),
        repeatVisitors: totals.repeatVisitors + (row.repeatVisitors || 0),
        total: totals.total + (row.total || 0),
      }),
      {
        vip: 0,
        media: 0,
        students: 0,
        delegation: 0,
        ec: 0,
        preEventReg: 0,
        eventDaysReg: 0,
        totalVisitors: 0,
        repeatVisitors: 0,
        total: 0,
      }
    );
  }, [rows]);

  function transformFootfallData(items) {
    if (!items || !Array.isArray(items)) return [];
    const transformedList = [];

    items.forEach((user) => {
      const {
        day1,
        day2,
        day3,
        day4,
        day5,
        day6,
        day7,
        day1timestamp,
        day2timestamp,
        day3timestamp,
        day4timestamp,
        day5timestamp,
        day6timestamp,
        day7timestamp,
        role,
        ...rest
      } = user;

      let entryCount = 0;

      const addEntry = (timestamp) => {
        if (!timestamp) return;
        const date = new Date(timestamp);
        const currentRole = entryCount > 0 ? `${role} (repeat)` : role;
        transformedList.push({
          ...rest,
          role: currentRole,
          Day: date.toISOString().split("T")[0],
          Time: date.toISOString().split("T")[1].split(".")[0],
        });
        entryCount++;
      };

      if (day1 && day1timestamp) addEntry(day1timestamp);
      if (day2 && day2timestamp) addEntry(day2timestamp);
      if (day3 && day3timestamp) addEntry(day3timestamp);
      if (day4 && day4timestamp) addEntry(day4timestamp);
      if (day5 && day5timestamp) addEntry(day5timestamp);
      if (day6 && day6timestamp) addEntry(day6timestamp);
      if (day7 && day7timestamp) addEntry(day7timestamp);
    });

    return transformedList;
  }

  const exportAuditReport = () => {
    const result = transformFootfallData(data);
    const filteredData = result.map((item) => {
      const {
        _id,
        user_id,
        barcode_id,
        eventsList,
        title,
        firstName,
        lastName,
        phone,
        email,
        companyName,
        instituteName,
        name,
        role: user_type,
        address,
        selectedCountry,
        selectedState,
        selectedCity,
        pin,
        website,
        telephone,
        designation,
        department,
        selectedEventSource,
        selectedIndustrySector,
        selectedCategories,
        profile_image,
        qrLink,
        createdOn,
        Day,
        Time,
      } = item;
      const facteq2024Categories =
        selectedCategories?.["FACTEQ 2024"]?.join(",") ||
        "No categories selected";
      const pmtx2024Categories =
        selectedCategories?.["PMTX 2024"]?.join(",") ||
        "No categories selected";
      const productCategories = `FACTEQ 2024: ${facteq2024Categories} and PMTX 2024: ${pmtx2024Categories}`;

      return {
        "Object Id": _id,
        "Profile Image": profile_image,
        "User ID": user_id,
        "Barcode ID": barcode_id,
        "User Type": user_type,
        "Events List": Array.isArray(eventsList) ? eventsList.join(", ") : "",
        Title: title,
        "First Name": firstName,
        "Last Name": lastName,
        name: name,
        "Phone Number": phone,
        Email: email,
        "Company/Institute Name": `${companyName ? companyName : ""}${
          instituteName ? instituteName : ""
        }`,
        Address: address,
        Country: selectedCountry?.name,
        State: selectedState?.name,
        City: selectedCity,
        "Postal Code": pin,
        Website: website,
        Telephone: telephone,
        Designation: designation,
        Department: department,
        "Event Source": selectedEventSource,
        "Industry Sector": selectedIndustrySector,
        "Product Categories": productCategories,
        "QR Link": qrLink,
        "Registration Date": extractDate(createdOn),
        "Registration Time": extractTime(createdOn),
        Day: Day,
        Time: Time,
      };
    });

    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "PMTX footfall report");
    XLSX.writeFile(wb, "pmtx_report.xlsx");
  };

  return (
    <Box>
      <CustomCircularP show={isLoading} />
      <Box p={2} flex={1}>
        <Box
          display={"flex"}
          flexDirection={isSmallScreen ? "column" : "row"}
          alignItems="center"
        >
          <Typography
            variant="h5"
            fontWeight={600}
            style={{ flex: 1, textAlign: isSmallScreen ? "center" : "left" }}
          >
            Footfall Report
          </Typography>

          <Button
            sx={{
              alignSelf: isSmallScreen ? "center" : "flex-end",
              mt: isSmallScreen ? 2 : 0,
              mr: 2,
            }}
            onClick={() => {
              navigate("/onsite/daywise-footfall");
            }}
            size="small"
            variant="outlined"
          >
            TimeWise Footfall report
          </Button>
          <Button
            sx={{
              alignSelf: isSmallScreen ? "center" : "flex-end",
              mt: isSmallScreen ? 2 : 0,
            }}
            onClick={() => {
              // exportAuditReport();
              setRefresh((prev) => !prev);
            }}
            size="small"
            variant="outlined"
            startIcon={<RefreshIcon />}
          >
            Refresh
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Day</TableCell>
                <TableCell align="right">VIP</TableCell>
                <TableCell align="right">Media</TableCell>
                <TableCell align="right">Students</TableCell>
                <TableCell align="right">Delegation</TableCell>
                <TableCell align="right">EC</TableCell>
                <TableCell align="right">Pre-Event Registration</TableCell>
                <TableCell align="right">Event Days Registration</TableCell>
                <TableCell align="right">
                  Total Visitors (excl. repeat)
                </TableCell>
                <TableCell align="right">Repeats</TableCell>
                <TableCell align="right">Total (incl. repeat)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.day}>
                  <TableCell component="th" scope="row">
                    {row.day}
                  </TableCell>
                  <TableCell align="right">{row.vip}</TableCell>
                  <TableCell align="right">{row.media}</TableCell>
                  <TableCell align="right">{row.students}</TableCell>
                  <TableCell align="right">{row.delegation}</TableCell>
                  <TableCell align="right">{row.ec}</TableCell>
                  <TableCell align="right">{row.preEventReg}</TableCell>
                  <TableCell align="right">{row.eventDaysReg}</TableCell>
                  <TableCell align="right">{row.totalVisitors}</TableCell>
                  <TableCell align="right">{row.repeatVisitors}</TableCell>
                  <TableCell align="right">{row.total}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell sx={{ fontSize: "16px" }} component="th" scope="row">
                  Totals
                </TableCell>
                <TableCell sx={{ fontSize: "16px" }} align="right">
                  {columnSums.vip}
                </TableCell>
                <TableCell sx={{ fontSize: "16px" }} align="right">
                  {columnSums.media}
                </TableCell>
                <TableCell sx={{ fontSize: "16px" }} align="right">
                  {columnSums.students}
                </TableCell>
                <TableCell sx={{ fontSize: "16px" }} align="right">
                  {columnSums.delegation}
                </TableCell>
                <TableCell sx={{ fontSize: "16px" }} align="right">
                  {columnSums.ec}
                </TableCell>
                <TableCell sx={{ fontSize: "16px" }} align="right">
                  {columnSums.preEventReg}
                </TableCell>
                <TableCell sx={{ fontSize: "16px" }} align="right">
                  {columnSums.eventDaysReg}
                </TableCell>
                <TableCell sx={{ fontSize: "16px" }} align="right">
                  {columnSums.totalVisitors}
                </TableCell>
                <TableCell sx={{ fontSize: "16px" }} align="right">
                  {columnSums.repeatVisitors}
                </TableCell>
                <TableCell sx={{ fontSize: "16px" }} align="right">
                  {columnSums.total}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
