import React, { useMemo, useState } from "react";
import {
  Card,
  CardContent,
  Stack,
  Typography,
  Button,
  CircularProgress,
  IconButton,
  Dialog,
  DialogContent,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { API_ENDPOINT } from "../../utils/constants";
import useCommonGetInit from "../../hooks/useCommonGetInit";
import { exportToExcel } from "../../utils/util";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CloseIcon from "@mui/icons-material/Close";

const IndustrySectorwiseCard = () => {
  const { data: sectorwiseData, isLoading: isSectorwiseLoading } =
    useCommonGetInit(
      `${API_ENDPOINT}user/a/get-industry-sectorwise-registrations`
    );
  const [isFullscreen, setFullscreen] = useState(false);

  // Transform the data to match the format for Recharts
  const dataBySector = useMemo(() => {
    if (!sectorwiseData || !Array.isArray(sectorwiseData.registrations))
      return [];

    return sectorwiseData.registrations
      .filter(
        (item) =>
          typeof item.industrySector === "string" && item.industrySector.trim()
      ) // Keep only valid strings that are not empty
      .map((item) => ({
        industrySector: item.industrySector.trim(),
        count: item.count,
      }));
  }, [sectorwiseData]);

  const handleFullscreenToggle = () => setFullscreen((prev) => !prev);

  // Limit items for compact view
  const displayedData = isFullscreen ? dataBySector : dataBySector.slice(0, 6); // Show top 6 sectors in compact view

  if (isSectorwiseLoading) {
    return (
      <Card>
        <CardContent>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            height={250}
          >
            <CircularProgress />
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      <Card>
        <CardContent>
          <Stack direction={"row"} spacing={2} alignItems="center">
            <Typography flex={1} variant="h6">
              Registrations by Industry Sector
            </Typography>
            <Button
              size="small"
              variant="outlined"
              startIcon={<FileDownloadIcon />}
              onClick={() =>
                exportToExcel(
                  dataBySector.map(({ industrySector, count }) => ({
                    industrySector,
                    count,
                  })),
                  "Registrations_by_Industry_Sector"
                )
              }
            >
              Export
            </Button>
            <IconButton onClick={handleFullscreenToggle}>
              <FullscreenIcon />
            </IconButton>
          </Stack>
          <ResponsiveContainer width="100%" height={250}>
            <BarChart data={displayedData} layout="vertical">
              <XAxis type="number" />
              <YAxis type="category" dataKey="industrySector" width={200} />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#82ca9d">
                <LabelList dataKey="count" position="right" />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </CardContent>
      </Card>

      {/* Fullscreen Chart Dialog */}
      <Dialog
        open={isFullscreen}
        onClose={handleFullscreenToggle}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent
          sx={{
            maxHeight: "80vh", // Limits the height of the dialog content
            overflowY: "auto", // Enables vertical scrolling for taller content
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h6">
              Full-Screen Registrations by Industry Sector
            </Typography>
            <IconButton onClick={handleFullscreenToggle}>
              <CloseIcon />
            </IconButton>
          </Stack>

          {/* Dynamically calculate chart height based on number of sectors */}
          <ResponsiveContainer
            width="100%"
            height={dataBySector.length * 50} // Adjust height dynamically, 50px per bar
          >
            <BarChart data={dataBySector} layout="vertical">
              <XAxis type="number" />
              <YAxis
                type="category"
                dataKey="industrySector"
                width={200} // Adjusted for potentially longer industry sector names
              />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#82ca9d">
                <LabelList dataKey="count" position="right" />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default IndustrySectorwiseCard;
