import React, { useContext } from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { LoadingButton } from "@mui/lab";
import { ExportContext } from "../../context/ExportContext";
const ExportExhibitorsButton = () => {
  const { exportExhibitors, exhibitorExporting } = useContext(ExportContext);
  return (
    <>
      <LoadingButton
        onClick={() => exportExhibitors()}
        variant="outlined"
        loading={exhibitorExporting}
        startIcon={<FileDownloadIcon />}
      >
        Export to Excel
      </LoadingButton>
    </>
  );
};

export default ExportExhibitorsButton;
