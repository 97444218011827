import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import { Alert, Box, Typography } from "@mui/material";
import { toastError } from "../../utils/util";
import CustomCircularP from "../../widgets/CustomCircularP";
import { API_ENDPOINT } from "../../utils/constants";
import { printRow } from "./ImtexBadgeDimension";
import { useNavigate, useParams } from "react-router-dom";
import { getOnsiteHeader, isOnsiteActivated } from "./utils";
import useCommonGetInit from "../../hooks/useCommonGetInit";
import MuPb from "../../widgets/MuPb";
function SelfPrinting() {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    data: Mdata,
    isLoading: loadingM,
    isError,
    error,
  } = useCommonGetInit(`${API_ENDPOINT}user/check-onsite?id=${id}`);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef();

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const scannedValue = event.target.value;
      fetchUserDetails(scannedValue);
      inputRef.current.value = "";
      inputRef.current?.focus();
    }
  };

  const fetchUserDetails = async (bid) => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `${API_ENDPOINT}user/get-scanned-visitor?barcode_id=${bid}`,
        getOnsiteHeader(Mdata.module)
      );
      printRow(data);
    } catch (error) {
      toastError(error);
    } finally {
      setIsLoading(false);
      inputRef.current.value = "";
      inputRef.current?.focus();
    }
  };
  useEffect(() => {
    if (Mdata) {
      if (!isOnsiteActivated(Mdata.module)) {
        navigate(-1);
      }
    }
  }, [Mdata]);
  if (loadingM) {
    return <MuPb />;
  }
  if (isError) {
    return <Alert severity="error">{error?.message}</Alert>;
  }

  return (
    <Box>
      <CustomCircularP show={isLoading} />
      <Typography variant="h5" textAlign={"center"} mt={2} mb={2}>
        The Cursor should be in the Input Field while Scanning.
      </Typography>
      <Box display={"table"} ml={"auto"} mr={"auto"}>
        <input
          ref={inputRef}
          id="barcodeInput"
          type="text"
          onKeyDown={handleKeyDown}
          placeholder="Scan QR Code"
          style={{
            padding: "10px",
            fontSize: "16px",
            width: "300px",
            textAlign: "center",
            borderRadius: "4px",
            border: "2px solid #000",
          }}
        />
      </Box>
    </Box>
  );
}

export default SelfPrinting;
