import React from "react";
import { Box, Container } from "@mui/material";
import OnsiteHeader from "./OnsiteHeader";
import OnsiteFooter from "./OnsiteFooter";

const OnsiteReuseContainer = ({ children, maxWidth = "md" }) => {
  return (
    <Box minHeight="100vh" display="flex" flexDirection="column">
      <OnsiteHeader />
      <Container sx={{ flexGrow: 1, py: 2 }}>{children}</Container>
      <OnsiteFooter />
    </Box>
  );
};

export default OnsiteReuseContainer;
